import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { Link } from 'react-router-dom'
import { ButtonContent } from "../global/boton/ButtonContent";
import Navmenu from './NavMenu';


const Header = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div>

            <div className='absolute mt-6 right-[5%] left-[5%] z-50  bg-black rounded-[80px]'>
                <div className='w-[90%] mx-auto flex justify-between items-center'>
                    {/* logo */}
                    <div className='w-[80%] md:w-[20%]  pr-3 md:pr-0 justify-center'>
                        <Link to={'/'}>
                            <img
                                src={`${rpdata?.dbPrincipal?.logo}`}
                                alt='no found'
                                className='w-[90%] '
                            />
                        </Link>
                    </div>
                    {/* nav menu */}
                    <nav className='w-[20%] md:w-[60%] text-center'>
                        <div className='relative'>
                            <Navmenu />
                        </div>
                    </nav>
                    <div className='hidden md:flex md:w-[20%]  justify-end '>
                    <ul className="flex text-white">
                        {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                            return (
                            <li key={index} className='mr-7'>
                                <a href={item.url} target="_blank" rel='noopener noreferrer' >
                                <i
                                    className={`fab fa-${item.icon}`}
                                    aria-hidden="true"
                                />
                                </a>
                            </li>
                            );
                        })}
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const beforeAfter = [
  {
    beforeImg: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F635067e729b1a4a38f0677ca%2Fgallery%2FCarcamo%20Hauling%20%26%20Demolition.-2022-10-21T23%3A39%3A36.243Z-0.jpg?alt=media&token=ce47987b-250b-41a7-bf26-406f1a57ab59',
    afterImg: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F635067e729b1a4a38f0677ca%2Fgallery%2FCarcamo%20Hauling%20%26%20Demolition.-2022-10-21T23%3A39%3A36.243Z-1.jpg?alt=media&token=2ee45e7b-046e-47df-a5b0-d275f38d7a99'
  },
  {
    beforeImg: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F635067e729b1a4a38f0677ca%2Fgallery%2FCarcamo%20Hauling%20%26%20Demolition.-2022-10-21T23%3A41%3A07.302Z-0.jpg?alt=media&token=2c843cfa-9a31-4ad4-a7f1-6688075f3cf0',
    afterImg: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F635067e729b1a4a38f0677ca%2Fgallery%2FCarcamo%20Hauling%20%26%20Demolition.-2022-10-21T23%3A41%3A07.302Z-1.jpg?alt=media&token=46a5084c-2096-46e5-9b78-89af7b38c476'
  },
]

const GalleryComponent = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <>
      {
        beforeAfter.length > 0 ?
          <div className="w-[90%] mx-auto py-14">
            <h2 className="text-center capitalize pb-[50px]">Before & after</h2>
            <div className={`flex flex-wrap justify-center gap-5`}>
              {
                beforeAfter.map((item, index) => {
                  return (
                    <img-comparison-slider key={index}>
                      <div slot="first" class="before">

                        <img slot="first" className="w-full h-full md:w-[400px] md:h-[400px]" src={`${item.beforeImg}`} />
                        <figcaption>Before</figcaption>
                      </div>
                      <div slot="second" class="after">

                        <img slot="second" className="w-full h-full md:w-[400px] md:h-[400px]" src={`${item.afterImg}`} />
                        <figcaption>After</figcaption>
                      </div>
                    </img-comparison-slider>
                  )
                })
              }
            </div>

          </div>
          : null

      }
      <h2 className="text-center pt-[100px]">Our Recent Projects</h2>

      <div className="py-[100px] flex justify-center">
        <div className="gallery-content w-4/5 mx-auto">
          <Gallery>
            {
              rpdata?.gallery?.length > 0 ? rpdata?.gallery?.map((item, index) => (
                <Item
                  key={index}
                  original={item}
                  thumbnail={item}
                  width="1024"
                  height="550"
                  padding="10px"
                >
                  {({ ref, open }) => <img ref={ref} onClick={open} src={item} alt="Not Found" />}
                </Item>
              ))
                : rpdata?.stock?.map((item, index) => (
                  <Item
                    key={index}
                    original={item}
                    thumbnail={item}
                    width="1024"
                    height="550"
                    padding="10px"
                  >
                    {({ ref, open }) => <img ref={ref} onClick={open} src={item} alt="Not Found" />}
                  </Item>
                ))
            }
          </Gallery>
        </div>
      </div>
    </>
  );
};

export default GalleryComponent;
